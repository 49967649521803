import { parseConstant } from "./helpers.service";

const documentTypes: { [key: string]: string } = {
  ID: "Front Side ID Scan",
  ID_BACK_SIDE: "Reverse Side ID Scan",
  PROOF_OF_ADDRESS: "Proof of Address Scan",
  PROOF_OF_OCCUPATION: "Proof of Occupation Scan",
  SOURCE_OF_FUNDS: "Source of Funds Scan",
  DRIVING_LICENCE: "Driver's License",
  PASSPORT: "USA Passport",
  UK_ID: "UK residency permit",
  EU_ID: "EU National ID card",
  WORK_PERMIT: "Work permit",
  PERMIT: "US Green Card",
};

const getDocumentLabel = (type: any): string => {
  if (type instanceof Object) {
    return documentTypes[type.documentType] || parseConstant(type.documentType);
  } else {
    return documentTypes[type] || parseConstant(type);
  }
};

export default {
  documentTypes,
  getDocumentLabel,
};
