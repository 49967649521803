import {
  all,
  fork,
  put,
  takeEvery,
  select,
  debounce,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import { get } from "lodash";

import { moneyTransferRoutes } from "../../../constants";
import { userDataSelector } from "../../user/user.selectors";
import { getRecipientSelectorById } from "../../recipient/recipients.selectors";
import {
  transferDataByCountrySelector,
  agentIdSelector,
  sourceCountrySelector,
} from "../../organization/organization.selectors";
import { SUCCESS, FAILURE } from "../../../utils/types/create-constants.types";
import { requestSagaHandler } from "../../../utils/sagas";
import globalConfig from "../../../../../environment";
import { Spinners } from "../../ui/ui.constants";
import {
  GET_SOURCE_CURRENCIES_ENDPOINT,
  getSourceCurrenciesTypes,
  GET_DESTINATION_CURRENCIES_ENDPOINT,
  getDestinationCurrenciesTypes,
  getPriceTypes,
  GET_PRICE_ENDPOINT,
  SET_TRANSFER_CURRENCY,
  SET_TRANSFER_DESTINATION_COUNTRY,
  SET_TRANSFER_PAYOUT_TYPE,
  FundingSources,
  TransactionOrigins,
  verifyPromocodeTypes,
  VERIFY_PROMOCODE_ENDPOINT,
  SET_TRANSFER_SOURCE_AMOUNT,
  SET_TRANSFER_DESTINATION_AMOUNT,
  TransferDetailsConstants,
  SET_TRANSFER_SOURCE_COUNTRY,
  SET_RESEND_DATA,
} from "../transfer.constants";
import {
  getSourceCurrenciesActions,
  getDestinationCurrenciesActions,
  getPriceActions,
  setSourceAmountAction,
  setDestinationAmountAction,
  resetTransferPriceAction,
  verifyPromocodeActions,
  setTransferRecipientAction,
} from "../transfer.actions";
import {
  payoutTypeSelector,
  destinationCountrySelector,
  destinationAmountSelector,
  sourceAmountSelector,
  destinationCurrencySelector,
  sourceCurrencySelector,
  priceResponseSelector,
  promocodeSelector,
  reverseCalcSelector,
} from "../transfer.selectors";
import toast from "../../../utils/toast";

const { OPERATOR_ID } = TransferDetailsConstants;

function* buildTransferCurrenciesPayloadSaga() {
  const payoutType = yield select(payoutTypeSelector);
  const destinationCountry = yield select(destinationCountrySelector);
  const sourceCountry = yield select(sourceCountrySelector);
  const operatorId = get(yield select(transferDataByCountrySelector), [
    destinationCountry,
    payoutType,
    OPERATOR_ID,
  ]);

  return (
    operatorId &&
    destinationCountry &&
    payoutType && {
      organizationId: globalConfig.organizationId,
      payoutType,
      destinationCountry,
      sourceCountry,
      operatorId,
    }
  );
}

function* verifyPromocodeBodyGetter(promoCode: string) {
  const sourceCountry = yield select(sourceCountrySelector);
  const sourceCurrency = yield select(sourceCurrencySelector);
  const destinationCountry = yield select(destinationCountrySelector);
  const payoutType = yield select(payoutTypeSelector);
  const { userId: senderId } = yield select(userDataSelector);

  return {
    data: {
      promoCode,
      destinationCountry,
      sourceCountry,
      sourceCurrency,
      payoutType,
      senderId,
      transactionOrigin: TransactionOrigins.WEB,
    },
  };
}

function* getTransferCurrenciesSaga({ type }: { type: string }) {
  const payload = yield buildTransferCurrenciesPayloadSaga();

  if (payload) {
    if (type === SET_TRANSFER_SOURCE_COUNTRY) {
      yield put(getSourceCurrenciesActions.request(payload));
      yield put(setSourceAmountAction());
    } else if (type === SET_TRANSFER_DESTINATION_COUNTRY) {
      yield put(getDestinationCurrenciesActions.request(payload));
      yield put(setDestinationAmountAction());
    } else if (type === SET_TRANSFER_PAYOUT_TYPE) {
      yield all([
        put(getSourceCurrenciesActions.request(payload)),
        put(getDestinationCurrenciesActions.request(payload)),
      ]);
      yield all([
        put(setSourceAmountAction()),
        put(setDestinationAmountAction()),
      ]);
    }
  }
}

const getSourceCurrenciesFork = fork(requestSagaHandler.post, {
  actions: getSourceCurrenciesActions,
  types: getSourceCurrenciesTypes,
  url: GET_SOURCE_CURRENCIES_ENDPOINT,
});

const getDestinationCurrenciesFork = fork(requestSagaHandler.post, {
  actions: getDestinationCurrenciesActions,
  types: getDestinationCurrenciesTypes,
  url: GET_DESTINATION_CURRENCIES_ENDPOINT,
});

const getPriceFork = fork(requestSagaHandler.post, {
  actions: getPriceActions,
  types: getPriceTypes,
  url: GET_PRICE_ENDPOINT,
  spinner: Spinners.CALCULATOR_SPINNER,
});

const verifyPromocodeFork = fork(requestSagaHandler.post, {
  paramsGetter: verifyPromocodeBodyGetter,
  actions: verifyPromocodeActions,
  types: verifyPromocodeTypes,
  url: VERIFY_PROMOCODE_ENDPOINT,
  spinner: Spinners.VERIFY_PROMOCODE_SPINNER,
});

function* calcPriceBySourceAmountSaga() {
  const reverse = yield select(reverseCalcSelector);
  if (!reverse) {
    yield getPriceSaga();
  }
}

function* calcPriceByDestinationAmountSaga() {
  const reverse = yield select(reverseCalcSelector);
  if (reverse) {
    yield getPriceSaga();
  }
}

function* handleResendTransaction({ payload }: any) {
  try {
    const recipient = yield select(
      getRecipientSelectorById(payload.recipientId),
    );
    yield put(setTransferRecipientAction(recipient));
    yield getPriceSaga();
    // yield put(push(moneyTransferRoutes.PAY));
  } catch (err) {
    resetTransferPriceAmountsSaga();
  }
}

function* resetTransferPriceAmountsSaga() {
  yield put(resetTransferPriceAction());
}

function* setTransferPriceAmountsSaga() {
  const { amountSentSRC, amountReceivedDEST } = yield select(
    priceResponseSelector,
  );

  const reverse = yield select(reverseCalcSelector);

  if (reverse) {
    yield put(setSourceAmountAction(amountSentSRC.toFixed(2)));
  } else {
    yield put(setDestinationAmountAction(amountReceivedDEST.toFixed(2)));
  }
}

function handlePriceFailureSaga({ error }: any) {
  toast.error({ message: error.message });
  resetTransferPriceAmountsSaga();
}

function* applyPromoCodeSaga() {
  const promoCode = yield select(promocodeSelector);

  if (promoCode) {
    yield getPriceSaga();
  }
}

function* getPriceSaga() {
  const sourceAmount = yield select(sourceAmountSelector);
  const destinationAmount = yield select(destinationAmountSelector);
  const reverse = yield select(reverseCalcSelector);
  const amount = reverse
    ? parseFloat(destinationAmount)
    : parseFloat(sourceAmount);

  if (!amount) {
    return;
  }
  const { userId: senderId } = yield select(userDataSelector);
  const agentId = yield select(agentIdSelector);
  const destinationCurrency = yield select(destinationCurrencySelector);
  const sourceCurrency = yield select(sourceCurrencySelector);
  const fundingSource = FundingSources.CREDIT_CARD;
  const payoutType = yield select(payoutTypeSelector);
  const receiveCountry = yield select(destinationCountrySelector);
  const sendCountry = yield select(sourceCountrySelector);
  const operatorId = get(yield select(transferDataByCountrySelector), [
    receiveCountry,
    payoutType,
    OPERATOR_ID,
  ]);
  const promoCode = yield select(promocodeSelector);

  yield put(
    getPriceActions.request({
      organizationId: globalConfig.organizationId,
      agentId,
      senderId,
      amount,
      destinationCurrency,
      sourceCurrency,
      fundingSource,
      payoutType,
      receiveCountry,
      sendCountry,
      operatorId,
      reverse,
      transactionOrigin: TransactionOrigins.WEB,
      promoCode,
    }),
  );
}

const calculatorSaga = all([
  getSourceCurrenciesFork,
  getDestinationCurrenciesFork,
  getPriceFork,
  verifyPromocodeFork,
  takeEvery(
    [
      SET_TRANSFER_SOURCE_COUNTRY,
      SET_TRANSFER_DESTINATION_COUNTRY,
      SET_TRANSFER_PAYOUT_TYPE,
      SET_TRANSFER_CURRENCY,
    ],
    resetTransferPriceAmountsSaga,
  ),
  takeEvery(
    [
      SET_TRANSFER_DESTINATION_COUNTRY,
      SET_TRANSFER_SOURCE_COUNTRY,
      SET_TRANSFER_PAYOUT_TYPE,
    ],
    getTransferCurrenciesSaga,
  ),
  debounce(1200, SET_TRANSFER_SOURCE_AMOUNT, calcPriceBySourceAmountSaga),
  debounce(
    1200,
    SET_TRANSFER_DESTINATION_AMOUNT,
    calcPriceByDestinationAmountSaga,
  ),
  takeEvery(verifyPromocodeTypes[SUCCESS], applyPromoCodeSaga),
  takeEvery(SET_RESEND_DATA, handleResendTransaction),

  takeEvery(getPriceTypes[SUCCESS], setTransferPriceAmountsSaga),
  takeEvery(getPriceTypes[FAILURE], handlePriceFailureSaga),
]);

export default calculatorSaga;
