import { createReducer } from "../../utils";
import { SUCCESS } from "../../utils/types/create-constants.types";
import { getMobileConfigTypes } from "../organization/organization.constants";
import { getUserDataTypes } from "../user/user.constants";

import {
  TOGGLE_SPINNER,
  TOGGLE_MODAL,
  TOGGLE_UNAVAILABLE,
  UNAVAILABLE_SERVICE,
  REDIRECT_ROUTE_AFTER_DOC_UPLOAD,
  SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE,
  Spinners,
  Modals,
} from "./ui.constants";
import { IUIstoreState } from "./ui.types";

const {
  CALCULATOR_SPINNER,
  LOADING_BANKS_SPINNER,
  LOADING_BANK_BRANCHES_SPINNER,
  TRANSACTIONS_SPINNER,
  TRANSACTION_NEW_SPINNER,
  LOADING_MOBILE_OPERATOR_SPINNER,
  GET_USER_DATA_SPINNER,
  GET_USER_CONTACT_OPTIONS,
  GET_AUTOCOMPLETE_OPTIONS,
  VERIFY_PROMOCODE_SPINNER,
  IFSC_SEARCH_SPINNER,
  LOADING_PAYOUT_TYPE_DETAILS_SPINNER,
  API_LOADING_SPINNER,
  SIGN_UP_SPINNER,
} = Spinners;

const {
  BANK_DETAILS_MODAL,
  PHONE_VERIFICATION_MODAL,
  TRANSACTION_INFO_MODAL,
  DUPLICATED_TRANSACTION_MODAL,
  SUPPORT_MODAL,
  PICKUP_LOCATIONS_MODAL,
  SKIP_UPLOAD_CONFIRMATION_MODAL,
  EMAIL_VERIFICATION_MODAL,
  MOBILE_CONFIRMATION_MODAL,
  USER_ALERT_MODAL,
  NATIONALITY_MODAL,
  USER_BLOCKED_MODAL,
  TWOFA_GENERATE_QR_CODE_MODAL,
  TWOFA_DISABLE_MODAL,
  UPDATE_ID_DATA_MODAL,
  SETUP_MPIN_MODAL,
} = Modals;

export default createReducer<IUIstoreState>(
  {
    [TOGGLE_SPINNER]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [TOGGLE_MODAL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [TOGGLE_UNAVAILABLE]: (state, { payload }) => ({
      ...state,
      [UNAVAILABLE_SERVICE]: payload,
    }),
    [SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE]: (state, { payload }) => ({
      ...state,
      [REDIRECT_ROUTE_AFTER_DOC_UPLOAD]: payload,
    }),
    [getMobileConfigTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [USER_ALERT_MODAL]: payload.hasAlert,
    }),
    [getUserDataTypes[SUCCESS]]: state => ({
      ...state,
      [USER_ALERT_MODAL]: false,
    }),
  },
  {
    [CALCULATOR_SPINNER]: false,
    [TRANSACTIONS_SPINNER]: false,
    [LOADING_BANKS_SPINNER]: false,
    [LOADING_BANK_BRANCHES_SPINNER]: false,
    [TRANSACTION_NEW_SPINNER]: false,
    [LOADING_MOBILE_OPERATOR_SPINNER]: false,
    [GET_USER_DATA_SPINNER]: false,
    [GET_AUTOCOMPLETE_OPTIONS]: false,
    [VERIFY_PROMOCODE_SPINNER]: false,
    [IFSC_SEARCH_SPINNER]: false,
    [LOADING_PAYOUT_TYPE_DETAILS_SPINNER]: false,
    [API_LOADING_SPINNER]: false,
    [SIGN_UP_SPINNER]: false,

    // [GET_USER_DOCUMENTS_SPINNER]: undefined,
    [BANK_DETAILS_MODAL]: false,
    [PHONE_VERIFICATION_MODAL]: false,
    [SUPPORT_MODAL]: false,
    [SKIP_UPLOAD_CONFIRMATION_MODAL]: false,
    [PICKUP_LOCATIONS_MODAL]: false,
    [TRANSACTION_INFO_MODAL]: false,
    [DUPLICATED_TRANSACTION_MODAL]: false,
    [GET_USER_CONTACT_OPTIONS]: false,
    [EMAIL_VERIFICATION_MODAL]: false,
    [MOBILE_CONFIRMATION_MODAL]: false,
    [USER_ALERT_MODAL]: false,
    [USER_BLOCKED_MODAL]: false,
    [NATIONALITY_MODAL]: false,
    [TWOFA_GENERATE_QR_CODE_MODAL]: false,
    [TWOFA_DISABLE_MODAL]: false,
    [UPDATE_ID_DATA_MODAL]: false,

    [UNAVAILABLE_SERVICE]: false,
    [REDIRECT_ROUTE_AFTER_DOC_UPLOAD]: null,
    [SETUP_MPIN_MODAL]: false,
  },
);
