import { Action } from "redux";

import { IOrganization } from "../organization/organization.types";
import { AutocompleteOptions } from "../../components/Form/FormAutocompleteInput";
import { FundingSources } from "../transfer/transfer.constants";
import { IVerficationDocument } from "../transfer/transfer.types";

import {
  IMpinOtpConfirmResponse,
  IMpinOtpSendResponse,
  UserStoreConstants,
} from "./user.constants";

const {
  USER_CONTACT_OPTIONS,
  AUTOCOMPLETE_ADDRESSES,
  USER_TRANSACTIONS,
  USER_CARDS,
  REJECTION_FLOW,
  MPIN_SETUP_OTP_SEND,
  MPIN_SETUP_OTP_CONFIRM,
} = UserStoreConstants;

export enum NotificationsTypes {
  EMAIL = "EMAIL",
  SMS = "SMS",
  PHONE = "PHONE",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  POST = "POST",
}

export interface ILoginFormValues {
  phoneCode?: string;
  phone?: string;
  email?: string;
  password?: string;
  verificationCode?: string;
}

export interface ITwoFAGenerateQRFormValues {
  userId?: string;
}

export interface ISignupFormValues {
  phoneCode: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface INewAccount {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  zipCode: string;
  password: string;
  city: string;
  address: string;
  phoneCode: string;
  phone: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
  houseNumber: string;
  [NotificationsTypes.PHONE]: boolean;
  [NotificationsTypes.SOCIAL_MEDIA]: boolean;
  [NotificationsTypes.POST]: boolean;
}

export interface ILoginSuccessAction extends Action {
  payload: {
    token: string;
    twoFAEnabled: boolean;
    twoFAEnforced: boolean;
  };
}

export interface ILoginFailAction extends Action {
  error: {
    message: string;
  };
}

export interface IVerifySuccessAction extends Action {
  payload: {
    verified: boolean;
  };
}

export type Token = string | null;
export type TwoFAEnabled = boolean | null;
export type TwoFAEnforced = boolean | null;
export type Verified = boolean | null;
export type ErrorMessage = string | null;

export interface IUserAccountType {
  accountType: string;
  connectedEntityId: string;
}

export interface IUserData extends ISignupFormValues {
  accounts: IUserAccountType[];
  active: boolean;
  address: string;
  addressCheckFailed: boolean;
  agentId: string;
  agentName: string;
  allowedToSend: boolean;
  apartmentNumber: string;
  city: string;
  cityOfBirth: string;
  complianceComment: string;
  complianceStatus: string;
  country: string;
  countryOfBirth: string;
  customerCardNumber: string;
  dateOfBirth: string;
  depositingAgentId: string;
  email: string;
  encryptedPassword: string;
  encryptedPin: string;
  eventId: string;
  firstName: string;
  fromEditModule: boolean;
  gender: string;
  houseNumber: string;
  idExpiryDate: string;
  idIssueByCountry: string;
  idIssueByOrganization: string;
  idIssuePlace: string;
  idIssuedDate: string;
  idNumber: string;
  idSerialNumber: string;
  idType: string;
  isDetached: string;
  lastName: string;
  lastUpdate: string;
  loggedInUserId: string;
  middleName: string;
  nationality: string;
  occupation: string;
  organizationData: IOrganization;
  organizationId: string;
  password: string;
  phoneCountry: string;
  phoneNumber: string;
  phoneVerified: boolean;
  pin: string;
  referralCode?: string;
  roles: string[];
  selfSignup: boolean;
  skipVerificationCode: boolean;
  socialSecurityNumber: string;
  state: string;
  tellerId: string;
  userContactOptions: string[];
  userId: string;
  verificationCode: string;
  zipCode: string;
  twoFAEnabled: boolean;
  updateIdDataPanelDisplayed: boolean;
  userType: string;
  hasMpin?: boolean;
  // notifications
  [NotificationsTypes.EMAIL]?: boolean;
  [NotificationsTypes.PHONE]?: boolean;
  [NotificationsTypes.SOCIAL_MEDIA]?: boolean;
  [NotificationsTypes.POST]?: boolean;
  [NotificationsTypes.SMS]?: boolean;
}

export interface IUserAccount {
  accountType: string;
}

export interface IUserTwoFAData {
  key: string;
  qrcode: string;
}

export interface IUserSignup {
  origin: string;
  organizationId?: string;
  verificationCode: string;
  country: string;
  firstName: string;
  lastName: string;
  middleName: string;
  nationality: string;
  dateOfBirth: string;
  email: string;
  city: string;
  houseNumber: string;
  address: string;
  zipCode: string;
  phoneNumber: string;
  password: string;
  accounts: IUserAccount[];
  roles: string[];
  userContactOptions: string[];
  idType?: string;
  idNumber?: string;
  idExpiryDate?: string;
  idIssueByCountry?: string;
  idIssuedDate?: string;
  userAgent?: string;
}
export interface IUserCard {
  id: number;
  tokenizedCard: string;
  lastFourDigits: string;
  expirationMonth: string;
  expirationYear: string;
  cardholderName: string;
  fundingSource: string;
  fundingSourceProcessorType: string;
  issuer: string;
}

export interface IUserState {
  token: Token;
  twoFAEnabled: TwoFAEnabled;
  twoFAEnforced: TwoFAEnforced;
  verified: Verified;
  creditCardForm: any;
  signupDocuments: IVerficationDocument;
  requestError: ErrorMessage;
  userData: IUserData | {};
  userTwoFAData: IUserTwoFAData | {};
  updateIdDataPanelDisplayed: boolean;
  getUserDocumentCheckIdReport: IUserDocReport | {};
  userDocuments: any | {};
  [USER_CONTACT_OPTIONS]: string[];
  [AUTOCOMPLETE_ADDRESSES]: AutocompleteOptions;
  [USER_TRANSACTIONS]?: ITransaction[];
  [USER_CARDS]: IUserCard[];
  signupReferralCode?: { referralCode: string; valid: boolean };
  [REJECTION_FLOW]: IRejectionFlowState;
  [MPIN_SETUP_OTP_SEND]?: IMpinOtpSendResponse | null;
  [MPIN_SETUP_OTP_CONFIRM]?: IMpinOtpConfirmResponse | null;
}

export interface IUserDoc {
  complianceStatus: string;
  description: string;
  documentId: string;
  documentType: string;
  documentSubType: string;
  externalReference: string;
  fileExt: string;
  hasHistory: boolean;
  historyDocuments: any[];
  lastUpdated: string;
  loggedInUserId: string;
  message: string;
  organizationId: string;
  path: string;
  preview: string;
  status: string;
  statusCode: number;
  updatedBy: string;
  userId: string;
  userName: string;
}

export interface IUserDocReport {
  breakdown: any;
  checkId: string;
  createdAt: any;
  documents: any[];
  href: string;
  id: string;
  name: string;
  properties: IUserDocReportProperties;
  result: string;
  status: string;
  subResult: string;
}

export interface IUserDocReportProperties {
  date_of_birth: string;
  date_of_expiry: string;
  document_numbers: any[];
  document_type: string;
  first_name: string;
  gender: string;
  issuing_country: string;
  last_name: string;
  nationality: string;
}

export interface ITransaction {
  accountNumber: string;
  agentName: string;
  amountReceived: string;
  amountSent: string;
  bankName: string;
  date: string;
  depositCode: string;
  destinationCountry: string;
  destinationCurrency: string;
  fundingSource: string;
  fxRate: number;
  fxRateCustom: number;
  fxRateForTransaction: number;
  fxRateWithCommission: number;
  mobileOperatorName: string;
  operatorUsed: string;
  operatorUsedId: string;
  payoutType: string;
  pickupCityCode: string;
  pickupLocationCode: string;
  pickupLocationName: string;
  receiverName: string;
  recipientId: string;
  relationshipToSender: string;
  reservationCode: string;
  senderName: string;
  sourceCurrency: string;
  sourceOfFunds: string;
  transactionFee: number;
  transactionId: string;
  transactionStatus: string;
  trasnactionFeeCurrency: string;
  type: string;
  useOfFunds: string;
}

export interface ITransactionDetail {
  transactionStatus: string;
  depositCode: string;
  type: string;
  reservationCode: string;
  amount: string;
  amountDest: string;
  amountSenderToPay: string;
  sourceCurrency: string;
  destinationCurrency: string;
  payoutType: string;
  pickupCode: string;
  pickupCorrespondentCode: string;
  mobileOperatorName: string;
  mobileOperatorCode: string;
  transactionDate: string;
  bankName: string;
  bankBranch: string;
  bankAccountNumber: string;
  bankCode?: string;
  ifscCode: string;
  transactionId: string;
  sender: any;
  receiver: any;
  priceResponse: any;
  operatorToUseId: string;
  pickupLocationName: string;
  transactionFee: number;
  fxRate: string;
  tellerName: string;
  agentId: string;
  operatorName: string;
  recipientId: string;
  mandatoryFields: any;
  transactionOperatorCode: string;
  pinCode: string;
  discount: number | string;
  canBeCancelled?: boolean;
  isPointToPoint: RsaHashedImportParams;
  operatorUsedId: string;
  useOfFunds?: string;
  relationship?: string;
  paymentType: FundingSources;
  transactionPaymentData: any;
  chargesFrom: string;
  externalApiId: string;
  agentName: string;
  complianceFailReason?: string;
  fxRateSRCDESTCustom?: number;
  cardEnprintedName?: string;
  cardNumber?: string;
  accountNumber?: string;
  loanDetails: any;
  shopPayoutTypeOptions: any;
  reverse: boolean;
  fromSenderToPay: boolean;
  creditCardValidThru?: string;
  comment?: string;
  pickupCityCode?: string;
  fundingSource: string;
  paymentStatus: string;
  dueDate?: string;
  discountAmountFixedInSRC: string;
  canBeAmended?: boolean;
  paidAmount?: number;
  promoCode?: string;
  dueAmount: number;
  purposeOfTransfer?: string;
  sourceOfFunds?: string;
  mobileTopup?: any;
}
export interface ITransactionDataByCode {
  statusCode: number;
  message: string;
  status: string;
  type: string;
  organizationId: string;
  agentId: string;
  userId: string;
  amount: string;
  numTransactions: number;
  revenue: string;
  pageNum: number;
  totalPages: number;
  startDateMillis: number;
  endDateMillis: number;
  reportResult: string;
  queryType: string;
  queryCode: string;
  transactionDetails: ITransactionDetail;
  timestamp: string;
  date: string;
  transactionId: string;
  sendCurrency: string;
  receiveCurrency: string;
  amountSent: string;
  amountReceived: any;
  transactionFee: string;
  transactionFeeCurrency: string;
  fxRateWithCommission: string;
  fxRateForTransaction: string;
  operatorUsedId: string;
  payoutType: string;
  amendFields?: any[];

  // IPayLaterTransaction
  dueDate?: string;
  paymentMethod?: FundingSources;
  sourceCurrency?: string;
  destinationCurrency?: string;
}

export enum RejectionReasonEnum {
  DEFAULT = "DEFAULT",
  ID_EXPIRED = "ID_EXPIRED",
}

export interface IRejectionFlowState {
  allowedDocTypes?: string[];
  isCancelable: boolean;
  reason: RejectionReasonEnum;
}

export enum IMPinConfirmSourceEnum {
  EMAIL = "EMAIL",
  SMS = "SMS",
}
