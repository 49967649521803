import organizationsConfig from "../../src/organizations.conf.json";
const { id: organizationId, name: organizationName } = organizationsConfig[
  process.env.REACT_APP_NAME
];

export default {
  port: process.env.PORT || 3000,
  gatewayMerchantId:
    process.env.GATEWAY_MERCHANT_ID ||
    "pk_test_5f7cc92c-962a-4c71-88a2-37c5a6205a6b",
  apiBase: process.env.API_HOST || "https://api.dev.ulagent.com",
  checkoutApiUrl: "https://api.sandbox.checkout.com",
  organizationId,
  organizationName,
  name: process.env.REACT_APP_ENV,
  payosuKey: "ed7c5e17-cb49-4f69-b62b-050c8adf19b1",
  payosuScript: "//widget.sandbox.payosu.com/index.js",
  agentId: "4903c26c-0b0d-4a12-adf8-6f02b11bcb25",
  country: "US",
  password:"W4rOzoYq8F^WzjM8gLXyo^wrhqosT!ko",
  secret:"EN93efE3nWXYMZ2JEuteqOfN6wqQKBT5"
};
