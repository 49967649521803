import { AnyAction, combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import { IAppState } from "./types";
import user from "./modules/user/user.reducers";
import recipients from "./modules/recipient/recipient.reducers";
import organization from "./modules/organization/organization.reducers";
import transfer from "./modules/transfer/transfer.reducers";
import ui from "./modules/ui/ui.reducers";

export type ReducerCreatorType = (
  history: History,
) => Reducer<IAppState, AnyAction>;

const rootReducer: ReducerCreatorType = (history: History) => (
  state: IAppState | any,
  action: AnyAction,
): IAppState => {
  return combineReducers({
    router: connectRouter(history),
    user,
    recipients,
    organization,
    transfer,
    ui,
  })(state, action);
};

export default rootReducer;
