import organizationsConfig from "../../src/organizations.conf.json";
const { id: organizationId, name: organizationName } = organizationsConfig[
  process.env.REACT_APP_NAME
];

export default {
  port: process.env.PORT || 3000,
  apiBase: process.env.API_HOST || "https://api.server.ulagent.com",
  checkoutApiUrl: "https://api.checkout.com",
  gatewayMerchantId: "pk_bkril3svcx3ticm57y5v3i6s5m4", // ack_eww2cwk2t6iu3gl244lbl7gxiy
  organizationId,
  organizationName,
  name: process.env.REACT_APP_ENV,
  payosuKey: "082976c7-3c97-49a0-8cfb-4a6f6962c02c",
  payosuScript: "//widget.live.payosu.com/index.js",
  agentId: "bd6e3755-2308-41c9-a2fa-d90b2be70720",
  country: "US",
  password:"6WScDw3LTz20YWf5@H%pqiA&AjbFRS@z",
  secret:"BuOKKrtoVGAm9gVzM2642uJRjbLh9K3Q"
};
