import { all, fork } from "redux-saga/effects";

import userSaga from "./modules/user/user.sagas";
import organizationSaga from "./modules/organization/organization.saga";
import recipientSaga from "./modules/recipient/recipient.saga";
import transferSaga from "./modules/transfer/transferSaga";

export default function* rootSaga() {
  return yield all([
    fork(userSaga),
    fork(organizationSaga),
    fork(recipientSaga),
    fork(transferSaga),
  ]);
}
