import {
  call,
  cancelled,
  put,
  race,
  take,
  select,
  all,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import Axios from "axios";
import isFunction from "lodash/isFunction";

import {
  toggleSpinner,
  toggleServiceUnavailable,
} from "../modules/ui/ui.actions";
import { toggleModal } from "../modules/ui/ui.actions";
import { HISTORY_GO_BACK, API_MESSAGE } from "../constants";
import { history } from "../../store";
import { logoutUserAction } from "../modules/user/user.actions";
import { selectUnavailableStatus } from "../modules/ui/ui.selectors";
import { Modals } from "../modules/ui/ui.constants";

import { api } from "./apiClient";
import {
  IMakeRequestSagaParams,
  IRequestHandlerSagaParams,
  IRequestSagaParams,
} from "./types/sagas.types";
import { SUCCESS, FAILURE } from "./types/create-constants.types";
import toast from "./toast";
import { isObject } from "lodash";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";
const DELETE = "DELETE";

function* makeRequest({
  url,
  method,
  actions,
  paramsGetter = (data: any) => ({ data }),
  action,
  spinner,
  navigate = {},
  toastMessage = {},
}: IMakeRequestSagaParams) {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  try {
    if (spinner) {
      yield put(toggleSpinner(spinner, true));
    }

    const params = yield call(paramsGetter, action.payload);
    const response = yield call(api, {
      headers: url
        ? url.includes("/unAuth/sendCode")
          ? { "x-client-header": "ul" }
          : null
        : null,
      cancelToken: source.token,
      method,
      url,
      ...params,
    });

    if (isObject(response.data)) {
      const {
        data: { status, ...rest },
      } = response;

      if (status === "FAIL" && url !== "/transactionNew/checkCompliance") {
        throw { status, ...rest };
      }

      yield put(actions.success(rest));
    } else {
      yield put(actions.success(response.data));
    }

    const messages = isFunction(toastMessage) ? toastMessage() : toastMessage;

    if (navigate[SUCCESS]) {
      yield navigateSideEffect(navigate[SUCCESS] || "");
    }
    if (messages[SUCCESS]) {
      toast.success({
        message: messages[SUCCESS],
        autoClose: messages.autoCloseSuccess,
      });
    }
    const isServiceUnavailable = yield select(selectUnavailableStatus);
    if (isServiceUnavailable) {
      yield put(toggleServiceUnavailable(false));
    }
  } catch (err) {
    console.log(err);
    if (err === null || err.status === 503) {
      yield put(toggleServiceUnavailable(true));
      return;
    }

    if (err.status === 401 && !err.config.url.includes("/user/actions")) {
      const response = [put(logoutUserAction())];
      yield all(response);
      return;
    }

    if (err.blocked === true) {
      yield put(toggleModal(Modals.USER_BLOCKED_MODAL, true));
    }
    yield put(actions.failure(err));

    if (navigate[FAILURE]) {
      yield navigateSideEffect(navigate[FAILURE] || "");
    }
    const messages = isFunction(toastMessage) ? toastMessage() : toastMessage;
    if (messages[FAILURE]) {
      const message =
        messages[FAILURE] === API_MESSAGE ? err.message : messages[FAILURE];
      toast.error({ message, autoClose: messages.autoCloseFailure });
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.canceled());
      source.cancel();
    }
    if (spinner) {
      yield put(toggleSpinner(spinner, false));
    }
  }
}

function* requestHandlerSaga({
  url,
  method,
  types,
  actions,
  paramsGetter = (data: any) => ({ data }),
  navigate,
  spinner,
  toastMessage,
}: IRequestHandlerSagaParams) {
  while (true) {
    const action = yield take(types.REQUEST);
    yield race({
      cancel: take(types.CANCEL),
      task: call(makeRequest, {
        action,
        actions,
        method,
        paramsGetter,
        types,
        url,
        spinner,
        navigate,
        toastMessage,
      }),
    });
  }
}

function* navigateSideEffect(route: string) {
  if (route === HISTORY_GO_BACK) {
    yield history.goBack();
  } else {
    yield put(push(route));
  }
}

const addMethod = (method: string) => (args: IRequestSagaParams) =>
  requestHandlerSaga({ ...args, method });

const addMethodToMakeRequest = (method: string) => (
  args: IMakeRequestSagaParams,
) => makeRequest({ ...args, method });

export const makeRequestSaga = {
  delete: addMethodToMakeRequest(DELETE),
  get: addMethodToMakeRequest(GET),
  patch: addMethodToMakeRequest(PATCH),
  post: addMethodToMakeRequest(POST),
  put: addMethodToMakeRequest(PUT),
};

export const requestSagaHandler = {
  delete: addMethod(DELETE),
  get: addMethod(GET),
  patch: addMethod(PATCH),
  post: addMethod(POST),
  put: addMethod(PUT),
};
