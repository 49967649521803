import {
  TOGGLE_SPINNER,
  Spinners,
  TOGGLE_MODAL,
  TOGGLE_UNAVAILABLE,
  Modals,
  SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE,
} from "./ui.constants";

export const toggleSpinner = (spinner: Spinners, isActive: boolean) => ({
  type: TOGGLE_SPINNER,
  payload: { [spinner]: isActive },
});

export const toggleModal = (modal: Modals, isActive: boolean) => ({
  type: TOGGLE_MODAL,
  payload: { [modal]: isActive },
});

export const toggleServiceUnavailable = (isUnavailable: boolean) => ({
  type: TOGGLE_UNAVAILABLE,
  payload: isUnavailable,
});

export const setDocumentUploadRedirectRoute = (route: string | null) => ({
  type: SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE,
  payload: route,
});
