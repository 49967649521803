import { ISelectItem } from "../../components/Select";

interface IOTPSettings {
  secondsToResend: number;
}

export interface IRegistrationField {
  inputType: string;
  isMandatory: boolean;
  isVisible: boolean;
  label: string;
  name: string;
  userFreeTypeOnValue?: string;
  values: ISelectItem[];
}

export interface IOrganization {
  acceptedCurrencies: string[];
  acceptedFundingSources: string[];
  agentId: string;
  autocompleteCountries: any;
  country: string;
  countryOperators: { [key: string]: string };
  customerCreditDaysToPay: number;
  destinationCountries: string[];
  email: string;
  enforceIdExpiry: boolean;
  extraData: any;
  hasCashRegisters: boolean;
  hasChargesFrom: boolean;
  hasCustomReports: boolean;
  hasCustomerCard: boolean;
  hasCustomerCredit: boolean;
  hasExchange: boolean;
  hasPOA: boolean;
  hasPaymentConfirmation: boolean;
  legalName: string;
  loggedInUserId: string;
  manualTransactionApproval: boolean;
  organizationAddress: string;
  organizationCountry: string;
  organizationId: string;
  organizationName: string;
  originCountries: string[];
  payoutTypes: string[];
  phoneNumber: string;
  receiptInfo: any;
  requireAddressComplianceVerification: boolean;
  requireDeclarations: boolean;
  requireIDComplianceVerification: boolean;
  requireRecipientDetails: boolean;
  requireTransactionDocuments: boolean;
  requiresPhoneVerification: boolean;
  requiresReleaseTransaction: boolean;
  requiresTwoSidedId: boolean;
  skipDefaultPreparePayout: boolean;
  sourcesOfFunds: string;
  supportsCustomFxRate: boolean;
  supportsDiscount: boolean;
  supportsPayout: boolean;
  transactionLimits: number;
  transferPurposes: string;
  webSite: string;
}

export interface IMobileConfigurationCountries {
  country: string;
  payoutTypes: any[];
}

export interface ITransferDataByCountry {
  [countryCode: string]: {
    [payoutType: string]: {
      operatorId: string;
    };
  };
}

export interface IMobileConfiguration {
  agentCountry: string;
  agentId: string;
  agentName: string;
  countries: IMobileConfigurationCountries;
  transferDataByCountry: ITransferDataByCountry;
  depositCodeLifeTime: number;
  hasAlert: boolean;
  loggedInUserId: string;
  organizationId: string;
  payinTypes: string[];
  title: string[];
  message: string;
  settings: any & {
    transactionOtpTimes: IOTPSettings;
  };
  sourceCurrencies?: string[];
  destinationCurrencies?: string[];
  paymentProcessors?: any; // mock
  pickupLocationsByCountry: IPickupLocationByCountry[];
}

export interface IPickupLocationByCountry {
  locations: string[];
  label?: string;
}

export interface IExtraOptions {
  recipientRelationships: string[];
  purposesOfTransfer: string[];
  sourcesOfFunds: string[];
  idDocTypes: string[];
  docTypes: string[];
}

export interface IAgentBusinessHour {
  openTimeHour: number;
  openTimeMinute: number;
  closeTimeHour: number;
  closeTimeMinute: number;
  dayOfWeek: number;
  id: string;
}

export interface IOrganizationAgent {
  name: string;
  city: string;
  country: string;
  address: string;
  phoneNumber: string;
  phoneNumber2: string;
  agentBusinessHours?: IAgentBusinessHour[];
}

export interface IOrganizationState {
  organizationData: IOrganization | {};
  mobileConfiguration: IMobileConfiguration | {};
  extraOptions: IExtraOptions | {};
  organizationAgents: IOrganizationAgent[];
  registrationFields: IRegistrationField[];
}

export enum PaymentProcessors {
  CHECKOUT = "CHECKOUT_CREDIT_CARD_PROCESSOR",
  BARCLAY = "BARCLAY_CREDIT_CARD_PROCESSOR",
  SAGEPAY = "CC_SAGE_PAY",
  SECURE_TRADING = "SECURE_TRADING",
  IDEAL = "IDEAL",
}

export enum OTPSource {
  OTP_CODE = "OTP_CODE",
  MPIN_CODE = "MPIN_CODE",
  LIFETIME_OTP_CODE = "LIFETIME_OTP_CODE",
}
